import React, { useState, useEffect } from "react";
import { Button, Modal, TextInput, Text } from "@mantine/core";
import { useCreateStore } from "../model/model";
import { useNavigate } from "react-router-dom";

const AddOrderModal = () => {
  const {
    setIsModalOpen,
    isModalOpen,
    createOrder,
    createOrderError,
    createOrderResponse,
  } = useCreateStore();
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleAddOrder = () => {
    if (value.trim() === "") {
      setError("Название заказа обязательно для заполнения");
    } else {
      createOrder(value);
    }
  };

  // Используем useEffect для обработки успешного создания заказа
  useEffect(() => {
    if (createOrderResponse) {
      setValue("");
      setError("");
      navigate(`/orders/${createOrderResponse.id}`);
      setIsModalOpen(false);
    }
  }, [createOrderResponse, navigate, setIsModalOpen]);

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title="Добавление заказа"
    >
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <TextInput
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            if (error) setError("");
          }}
          label="Название заказа"
          placeholder="HONDA FREED GB3-1174957"
          style={{ flexGrow: 1, marginRight: "10px" }}
          error={error}
        />
        <Button
          variant="filled"
          radius="md"
          onClick={handleAddOrder}
          disabled={value.trim() === ""}
        >
          Добавить
        </Button>
      </div>
      <Text color="red.4">{createOrderError}</Text>
      {createOrderResponse && <Text color="tial.4">Успех</Text>}
      {createOrderResponse?.id}
    </Modal>
  );
};

export default AddOrderModal;
