import create from "zustand";
import OrderService from "../services/OrderService";
import { Order, TrackingOrders } from "../models/List/TrackingOrders";
import { OrdersExecution } from "../models/List/OrdersExecution";
import { OneTask, Tasks } from "../models/Task/Task";
import SubService from "../services/SubService";
import { IFile } from "../models/File/File";
import FileService from "../services/FileService";
import { CompleteTask } from "../models/Task/TaskComplete";
import TaskService from "../services/TaskService";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { IUpdateExecution } from "models/List/UpdateExecotion";
import imageCompression from "browser-image-compression";

interface OrderStoreState {
  orders: TrackingOrders | null;
  tasks: Tasks | null;
  OneTask: OneTask | null;
  orderExecutions: OrdersExecution | null;
  selectedOrderId: string | null;
  isTaskModalOpen: boolean;
  isSliderOpen: boolean;
  isExecutionModalOpen: boolean;
  executionId: string | null;
  isModalOpen: boolean;
  formFiles: { [key: string]: File[] };
  setFormFiles: (value: { [key: string]: File[] }) => void;
  resetFormFiles: () => void;

  subLink: string | "";
  fileResponse: IFile[] | null;
  currentPhotos:
    | {
        id?: string;
        thumbnail: string;
        original: string;
        text: string;
        type: any;
      }[]
    | null;
  setCurrentPhotos: (
    photos:
      | {
          id?: string;
          thumbnail: string;
          original: string;
          text: string;
          type: any;
        }[]
      | null
  ) => void;
  getTaskById: (orderId: string, TaskId: string) => void;
  getOrders: (
    page: number,
    size: number,

    searchString?: string | undefined,
    customerLogin?: string | undefined
  ) => Promise<void>;
  orderById: Order | null;
  getOrdersExecutions: (id: string) => Promise<void>;
  getOrderById: (id: string) => Promise<void>;
  setPhotoIndex: (index: number) => void;
  setIsExecutionModalOpen: (status: boolean, executionId: string) => void;
  getOrdersTasks: (id: string) => Promise<void>;
  setFileResponse: (file: File | IFile) => Promise<AxiosResponse<IFile, any>>;
  setMultipleFileResponse: (files: File[], fieldId: string) => void;
  postExecutionUpdate: (
    orderId: string,
    ExecutionId: string,
    executionUpdateData: IUpdateExecution
  ) => void;
  postExecutionUpdateResponse: any;
  taskError: any | null;
  setTaskComplete: (
    orderId: string,
    taskId: string,
    completeTask: CompleteTask
  ) => void;
  setSelectedOrder: (id: string | null) => void;
  openModal: () => void;
  setTaskModalOpen: (status: boolean) => void;
  closeModal: () => void;
  resetFileResponse: () => void;
  getSubLink: () => Promise<void>;
  resetOneTask: () => void;
  photoIndex: number | null;
  customerLogin: string | null;
  setCustomerLogin: (customerLogin: string) => void;
}
const useOrderStore = create<OrderStoreState>((set) => ({
  currentPhotos: null,
  orderById: null,
  customerLogin: null,
  postExecutionUpdateResponse: null,
  executionId: null,
  isExecutionModalOpen: false,
  photoIndex: null,
  taskError: null,
  formFiles: {},
  isTaskModalOpen: true,
  orders: null,
  tasks: null,
  selectedOrderId: null,
  orderExecutions: null,
  isSliderOpen: false,
  isModalOpen: false,
  subLink: "",
  fileResponse: null,
  OneTask: null,

  getOrders: async (
    page: number,
    size: number,

    searchString?: string | undefined,
    customerLogin?: string | undefined
  ) => {
    try {
      const response = await OrderService.getOrders(
        page,
        size,
        searchString,
        customerLogin
      );
      set({ orders: response.data });
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        window.location.href = "/auth";
      } else {
        console.error("Ошибка при получении заказов:", error);
      }
    }
  },
  getOrdersExecutions: async (id: string) => {
    try {
      const response = await OrderService.getOrderExecutions(id);
      set({ orderExecutions: response.data });
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
      } else {
        console.error("Ошибка при получении заказов:", error);
      }
    }
  },
  getOrderById: async (id: string) => {
    try {
      const response = await OrderService.getOrderById(id);
      set({ orderById: response.data });
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
      } else {
        console.error("Ошибка при получении заказов:", error);
      }
    }
  },
  getTaskById: async (orderId: string, TaskId: string) => {
    try {
      const response = await OrderService.getTaskById(orderId, TaskId);
      set({ OneTask: response.data });
      set({ taskError: null });
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
      } else {
        console.error("Ошибка при получении заказов:", error);
      }
    }
  },
  getOrdersTasks: async (id: string) => {
    try {
      const response = await OrderService.getOrdersTasks(id);
      set({ tasks: response.data });
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
      } else {
        console.error("Ошибка при получении заказов:", error);
      }
    }
  },
  setMultipleFileResponse: async (files: File[], fieldId: string) => {
    try {
      const responses = await Promise.all(
        files.map(async (file) => {
          return await useOrderStore.getState().setFileResponse(file);
        })
      );

      set((state) => {
        const uniqueFiles = files.filter(
          (file) =>
            !(state.formFiles[fieldId] || []).some(
              (existingFile) => existingFile.name === file.name
            )
        );

        return {
          ...state,
          fileResponse: [
            ...(state.fileResponse || []),
            ...responses,
          ] as IFile[],
          formFiles: {
            ...state.formFiles,
            [fieldId]: [...(state.formFiles[fieldId] || []), ...uniqueFiles],
          },
        };
      });
    } catch (error) {
      console.error("Error uploading multiple files:", error);
      throw error;
    }
  },

  getSubLink: async () => {
    try {
      const response = await SubService.telegramSubscribe();
      set({ subLink: response.data.value });
      //window.open(response.data.value, "_blank");
    } catch (error) {
      console.error("Error fetching telegramBotLink:", error);
    }
  },
  setFileResponse: async (
    file: File | IFile
  ): Promise<AxiosResponse<IFile, any>> => {
    try {
      // Убираем параметры для сжатия и само сжатие
      // const options = {
      //   maxSizeMB: process.env.REACT_APP_OPTIMIZATION_MAX_SIZE
      //     ? parseInt(process.env.REACT_APP_OPTIMIZATION_MAX_SIZE)
      //     : 1,
      //   maxWidthOrHeight: process.env.REACT_APP_OPTIMIZATION_MAX_WIDTH_OR_HEIGHT
      //     ? parseInt(process.env.REACT_APP_OPTIMIZATION_MAX_WIDTH_OR_HEIGHT)
      //     : 1920,
      //   useWebWorker: !isSafariOrIOS(),
      // };

      let fileToUpload: File | Blob = file as File;

      // Убираем проверку типа файла и сжатие
      // if ((file as File).type.startsWith("image/") && !isSafariOrIOS()) {
      //   const compressedFile = await imageCompression(file as File, options);
      //   fileToUpload = new File([compressedFile], file.name, {
      //     type: compressedFile.type,
      //     lastModified: Date.now(),
      //   });
      // } else {
      //   console.log(
      //     "File is not an image or compression is disabled for iOS, skipping compression."
      //   );
      // }

      const response = await FileService.fileUpload(fileToUpload as File);
      return response;
    } catch (error) {
      console.error("Error file upload:", error);
      throw error;
    }
  },

  setTaskComplete: async (
    orderId: string,
    taskId: string,
    completeTask: CompleteTask
  ) => {
    try {
      const response = await TaskService.taskComplete(
        orderId,
        taskId,
        completeTask
      );
      await useOrderStore.getState().getOrdersExecutions(orderId);
      await useOrderStore.getState().getOrdersTasks(orderId);
      set({ taskError: null });
    } catch (error) {
      console.error(error);
      set({ taskError: (error as any).response.data.message });
    }
  },
  postExecutionUpdate: async (
    orderId: string,
    ExecutionId: string,
    executionUpdateData: IUpdateExecution
  ) => {
    try {
      set({ taskError: null });
      const response = await OrderService.postExecutionUpdate(
        orderId,
        ExecutionId,
        executionUpdateData
      );

      set({ postExecutionUpdateResponse: response });
      await useOrderStore.getState().getOrdersExecutions(orderId);
      await useOrderStore.getState().getOrdersTasks(orderId);
      set({ taskError: null });
    } catch (error) {
      console.error(error);
      set({ taskError: (error as any).response.data.message });
    }
  },
  setFormFiles: (newFiles) =>
    set((state) => ({
      formFiles: {
        ...state.formFiles,
        ...newFiles,
      },
    })),
  resetFormFiles: () =>
    set(() => ({
      formFiles: {},
    })),
  setTaskModalOpen: (status: boolean) => set({ isTaskModalOpen: status }),
  setIsExecutionModalOpen: (status: boolean, executionId: string) => {
    set({ isExecutionModalOpen: status, executionId: executionId });
    set({ taskError: null });
    set({ postExecutionUpdateResponse: null });
  },
  setPhotoIndex: (index: number) => set({ photoIndex: index }),
  setSelectedOrder: (id: string | null) => set({ selectedOrderId: id }),
  openModal: () => set({ isModalOpen: true }),
  closeModal: () => set({ isModalOpen: false }),
  resetFileResponse: () => set({ fileResponse: null }),
  setCurrentPhotos: (photos) => set({ currentPhotos: photos }),
  resetOneTask: () => set({ OneTask: null }),
  setCustomerLogin: (customerLogin: string) =>
    set({ customerLogin: customerLogin }),
}));

export default useOrderStore;
